export default {
  order: {
    rows: [{
      id: 1,
      code: 'O-0001',
      customer_id: 1,
      customer_name: 'นายทรง ยันลือ',
      created: '',
      updated: '',
      date_order: '02/04/2021',
      status_order: 0,
      status_payment: 1,
      status_delivery: 1,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทองแดง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: 1,
      issue_type: 1,
      status_inspection: 1,
      issue_name: 'ปัญหาภายนอก',
      store_name: 'หรีด ณ วัด'
      // ไม่มีใน class หรือ erd
      // status_order_name: 'เปิดการขาย',
      // status_payment_name: 'รอชำระเงิน',
      // status_delivery_name: 'รอรููปจัดเสร็จ'
    }, {
      id: 2,
      code: 'O-0002',
      customer_id: 2,
      customer_name: 'นายสัญญา ยันลือ',
      created: '',
      updated: '',
      date_order: '02/04/2021',
      status_order: 1,
      status_payment: 1,
      status_delivery: 3,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทอง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: 2,
      issue_type: 3,
      status_inspection: 1,
      issue_name: 'ปัญหาจากลูกค้า',
      store_name: 'หรีด ณ วัด'
      // ไม่มีใน class หรือ erd
      // status_order_name: 'ยืนยันการขาย',
      // status_payment_name: 'รอชำระเงิน',
      // status_delivery_name: 'รอรููปจัดเสร็จ'
    }, {
      id: 3,
      code: 'O-0003',
      customer_id: 3,
      customer_name: 'นายมั่นคง ยันลือ',
      created: '',
      updated: '',
      date_order: '02/04/2021',
      status_order: 2,
      status_payment: 3,
      status_delivery: 4,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทองแดง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: 3,
      issue_type: 4,
      status_inspection: 1,
      issue_name: 'ปัญหาจากซัพพลายเออร์',
      store_name: 'หรีด ณ วัด'
      // ไม่มีใน class หรือ erd
      // status_order_name: 'เปิดการขาย',
      // status_payment_name: 'ชำระเงินแล้ว',ตรวจสอบการชำระเงินแล้ว
      // status_delivery_name: 'รอรููปจัดเสร็จ'
    }, {
      id: 4,
      code: 'O-0004',
      customer_id: 4,
      customer_name: 'นางดอกไม้ ยันลือ',
      created: '',
      updated: '',
      date_order: '02/04/2021',
      status_order: 0,
      status_payment: 2,
      status_delivery: 1,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทองแดง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: '',
      issue_type: 2,
      status_inspection: 3,
      issue_name: 'ปัญหาภายใน',
      store_name: 'หรีด ณ วัด'
      // ไม่มีใน class หรือ erd
      // status_order_name: 'เปิดการขาย',
      // status_payment_name: 'รอชำระเงิน',
      // status_delivery_name: 'รอรููปจัดเสร็จ'
    }, {
      id: 5,
      code: 'O-0004',
      customer_id: 5,
      customer_name: 'นางกลอย ยันลือ',
      created: '',
      updated: '',
      date_order: '02/04/2021',
      status_order: 0,
      status_payment: 1,
      status_delivery: 2,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทองแดง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: '',
      issue_type: 1,
      status_inspection: 2,
      issue_name: 'ปัญหาภายนอก',
      store_name: 'หรีด ณ วัด'
      // ไม่มีใน class หรือ erd
      // status_order_name: 'เปิดการขาย',
      // status_payment_name: 'รอชำระเงิน',
      // status_delivery_name: 'รอรููปจัดเสร็จ'
    }],
    paging: {
      rowTotal: 1
    }
  },
  status_order: [{
    id: 0,
    name: 'เปิดการขาย'
  }, {
    id: 1,
    name: 'ยืนยันการขาย'
  }, {
    id: 2,
    name: 'Approved'
  }, {
    id: 3,
    name: 'ส่งรายการให้ร้านค้า'
  }, {
    id: 4,
    name: 'ปิดการขายเรียบร้อย'
  }, {
    id: 5,
    name: 'พบปัญหารอการแก้ไข'
  }],
  status_payment: [{
    id: 1,
    name: 'รอชำระเงิน'
  }, {
    id: 2,
    name: 'ชำระเงินแล้ว' // ตรวจสอบการชำระเงินแล้ว
  }, {
    id: 3,
    name: 'ยืนยันการชำระเงินแล้ว'
  }],
  status_refund: [{
    id: 0,
    name: 'ปกติไม่มีการคืนเงิน'
  }, {
    id: 1,
    name: 'คืนเงินบางส่วน'
  }, {
    id: 2,
    name: 'คืนเงินเต็มจำนวน'
  }],
  status_delivery: [{
    id: 1,
    name: 'รอรูปจัดเสร็จ'
  }, {
    id: 2,
    name: 'ส่งรูปจัดเสร็จแล้ว'
  }, {
    id: 3,
    name: 'รอรูปผู้รับ'
  }, {
    id: 4,
    name: 'ส่งรูปผู้รับแล้ว'
  }],
  stores: [{
    id: 1,
    name: 'หรีด ณ วัด',
    website: 'https://www.wreathnawat.com/',
    created: '',
    updated: '',
    address: '121/232 ชั้น 2',
    phone: '0211212121',
    email: 'mail@mail.com',
    facebook: '@facebook',
    instagram: '@instagram',
    line: '@line',
    store_type: 1
  }, {
    id: 2,
    name: 'หรีดมาลา',
    website: 'https://www.wreathnawat.com/',
    created: '',
    updated: '',
    address: '121/232 ชั้น 2',
    phone: '0211212121',
    email: 'mail@mail.com',
    facebook: '@facebook',
    instagram: '@instagram',
    line: '@line',
    store_type: 1
  }, {
    id: 3,
    name: 'หรีดสเตชั่น',
    website: 'https://www.wreathnawat.com/',
    created: '',
    updated: '',
    address: '121/232 ชั้น 2',
    phone: '0211212121',
    email: 'mail@mail.com',
    facebook: '@facebook',
    instagram: '@instagram',
    line: '@line',
    store_type: 1
  }, {
    id: 4,
    name: 'เลอหรีด',
    website: 'https://www.wreathnawat.com/',
    created: '',
    updated: '',
    address: '121/232 ชั้น 2',
    phone: '0211212121',
    email: 'mail@mail.com',
    facebook: '@facebook',
    instagram: '@instagram',
    line: '@line',
    store_type: 1
  }, {
    id: 5,
    name: 'หรีดแทนบุญ',
    website: 'https://www.wreathnawat.com/',
    created: '',
    updated: '',
    address: '121/232 ชั้น 2',
    phone: '0211212121',
    email: 'mail@mail.com',
    facebook: '@facebook',
    instagram: '@instagram',
    line: '@line',
    store_type: 1
  }, {
    id: 6,
    name: 'Love You Flower',
    website: 'https://www.wreathnawat.com/',
    created: '',
    updated: '',
    address: '121/232 ชั้น 2',
    phone: '0211212121',
    email: 'mail@mail.com',
    facebook: '@facebook',
    instagram: '@instagram',
    line: '@line',
    store_type: 2
  }, {
    id: 7,
    name: 'A Flower Room',
    website: 'https://www.wreathnawat.com/',
    created: '',
    updated: '',
    address: '121/232 ชั้น 2',
    phone: '0211212121',
    email: 'mail@mail.com',
    facebook: '@facebook',
    instagram: '@instagram',
    line: '@line',
    store_type: 2
  }, {
    id: 8,
    name: 'Frunt n Flora',
    website: 'https://www.wreathnawat.com/',
    created: '',
    updated: '',
    address: '121/232 ชั้น 2',
    phone: '0211212121',
    email: 'mail@mail.com',
    facebook: '@facebook',
    instagram: '@instagram',
    line: '@line',
    store_type: 2
  }],
  customers: [{
    id: 1,
    name: 'บริษัท ดอกไม้',
    contact: 'นายเก่ง',
    phone: '081-633-0101',
    line: '@flower',
    email: 'flower@gmail.com',
    billing_address: '',
    tax_id: '',
    address: '',
    created: '',
    updated: '',
    remark: '-',
    credit_status: 0,
    credit_line: '2000'
  }, {
    id: 2,
    name: 'บ้านสวน',
    contact: 'ทองแดง',
    phone: '081-010-0101',
    line: '@flower34	',
    email: 'fxdd@gmail.com',
    billing_address: '',
    tax_id: '',
    address: '',
    created: '',
    updated: '',
    remark: 'อยู่หลังดอนเมือง',
    credit_status: 1,
    credit_line: '1500'
  }],
  locations: [{
    id: 1,
    group_id: '',
    type: 1,
    name: 'โรงพยาบาล',
    children: [],
    remark: '',
    address: 'โรงพยาบาลพระมงกุฎเกล้า :ถนนราชวิถี',
    city: 1,
    subdistrict: 2,
    district: 2,
    region: '',
    // ไม่มีตัวแปรเหล่านี้ใน diagram
    city_name: 'กทม.',
    subdistrict_name: 'ทุ่งพญาไท',
    district_name: 'ราชเทวี'
  }, {
    id: 2,
    group_id: '',
    type: 1,
    name: 'ทาวน์โฮม',
    children: [],
    remark: '',
    address: '3 ซ.ลาดพร้าว 101 ซ.46 (กิตติจิตต์)',
    city: 1,
    subdistrict: 1,
    district: 1,
    region: '',
    // ไม่มีตัวแปรเหล่านี้ใน diagram
    city_name: 'กทม.',
    subdistrict_name: 'คลองจั่น',
    district_name: 'บางกะปิ'
  }],
  products: [{
    id: 1,
    cat_id: 1,
    code: 'B124',
    name: 'บุษปวัน',
    status: 1,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: '-',
    size: 'M',
    tags: ['ลิลลี่'],
    // mapping_product
    price: '300',
    type: 4
  }, {
    id: 2,
    cat_id: 2,
    code: 'A215',
    name: 'กุหลาบ',
    status: 0,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: 'แดง',
    size: 'L',
    tags: [],
    // mapping_product
    price: '300',
    type: 4
  }, {
    id: 3,
    cat_id: 2,
    code: 'A0XX',
    name: 'พวงหรีดดอกไม้สด',
    status: 0,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: 'แดง',
    size: 'L',
    tags: [],
    // mapping_product
    price: '300',
    type: 1
  }, {
    id: 4,
    cat_id: 2,
    code: 'B0XX',
    name: 'พวงหรีดพัดลม',
    status: 0,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: 'แดง',
    size: 'L',
    tags: [],
    // mapping_product
    price: '300',
    type: 1
  }, {
    id: 5,
    cat_id: 2,
    code: 'C0XX',
    name: 'พวงหรีดผ้า',
    status: 0,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: 'แดง',
    size: 'L',
    tags: [],
    // mapping_product
    price: '300',
    type: 2
  }, {
    id: 6,
    cat_id: 1,
    code: 'D0XX',
    name: 'พวงหรีดต้นไม้',
    status: 1,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: '-',
    size: 'M',
    tags: ['ลิลลี่'],
    // mapping_product
    price: '300',
    type: 1
  }, {
    id: 7,
    cat_id: 2,
    code: 'E0XX',
    name: 'พวงหรีดนาฬิกา',
    status: 0,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: 'แดง',
    size: 'L',
    tags: [],
    // mapping_product
    price: '300',
    type: 1
  }, {
    id: 8,
    cat_id: 2,
    code: 'F0XX',
    name: 'พวงหรีดดอกไม้ประดิษฐ์',
    status: 0,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: 'แดง',
    size: 'L',
    tags: [],
    // mapping_product
    price: '300',
    type: 3
  }, {
    id: 9,
    cat_id: 2,
    code: 'FN10x',
    name: 'จัดดอกไม้หน้าศพ',
    status: 0,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: 'แดง',
    size: 'L',
    tags: [],
    // mapping_product
    price: '300',
    type: 1
  }, {
    id: 10,
    cat_id: 2,
    code: 'A001',
    name: 'พวงหรีดดอกไม้สด',
    status: 0,
    imgs: '',
    brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    detail: '',
    color: 'แดง',
    size: 'L',
    tags: [],
    // mapping_product
    price: '300',
    type: 1
  }],
  channels: [{
    id: 1,
    name: 'Line'
  }, {
    id: 2,
    name: 'Hotline'
  }, {
    id: 3,
    name: 'Facebook Messenger'
  }],
  orderMappingSupplier: [{
    id: 1,
    supplier_id: 2,
    supplier_name: 'บ้านสวน',
    order_amount_per_day: '1/20',
    order_amount_per_month: '30/200',
    price: 0,
    delivery_price: 20,
    total: 20
  }, {
    id: 2,
    supplier_id: 1,
    supplier_name: 'บริษัท ดอกไม้',
    order_amount_per_day: '1/20',
    order_amount_per_month: '30/200',
    price: 190,
    delivery_price: 50,
    total: 240
  }],
  search_filters: {
    code_filters: [{
      filter_count: 2,
      id: 1,
      name: 1
    }, {
      filter_count: 2,
      id: 2,
      name: 2
    }, {
      filter_count: 2,
      id: 3,
      name: 3
    }, {
      filter_count: 2,
      id: 4,
      name: 4
    }, {
      filter_count: 2,
      id: 5,
      name: 5
    }],
    name_filters: [{
      filter_count: 2,
      id: 1,
      name: 'นายทรง ยันลือ'
    }, {
      filter_count: 2,
      id: 2,
      name: 'นายสัญญา ยันลือ'
    }, {
      filter_count: 2,
      id: 3,
      name: 'นายมั่นคง ยันลือ'
    }, {
      filter_count: 2,
      id: 4,
      name: 'นางดอกไม้ ยันลือ'
    }, {
      filter_count: 2,
      id: 5,
      name: 'นางกลอย ยันลือ'
    }],
    order_name_filters: [{
      filter_count: 2,
      id: 0,
      name: 'เปิดการขาย'
    }, {
      filter_count: 2,
      id: 1,
      name: 'ยืนยันการขาย'
    }, {
      filter_count: 2,
      id: 2,
      name: 'Approved',
    }, {
      filter_count: 2,
      id: 3,
      name: 'ส่งรายการให้ร้านค้า'
    }, {
      filter_count: 2,
      id: 4,
      name: 'ปิดการขายเรียบร้อย'
    }, {
      filter_count: 2,
      id: 5,
      name: 'พบปัญหารอการแก้ไข'
    }],
    payment_name_filters: [{
      filter_count: 2,
      id: 1,
      name: 'รอชำระเงิน'
    }, {
      filter_count: 2,
      id: 2,
      name: 'ชำระเงินแล้ว' // ตรวจสอบการชำระเงินแล้ว
    }],
    refund_name_filters: [{
      filter_count: 2,
      id: 0,
      name: 'ปกติไม่มีการคืนเงิน'
    }, {
      filter_count: 2,
      id: 1,
      name: 'คืนเงินบางส่วน'
    }, {
      filter_count: 2,
      id: 2,
      name: 'คืนเงินเต็มจำนวน'
    }],
    delivery_name_filters: [{
      filter_count: 2,
      id: 1,
      name: 'รอรููปจัดเสร็จ'
    }, {
      filter_count: 2,
      id: 2,
      name: 'ส่งรูปจัดเสร็จแล้ว'
    }, {
      filter_count: 2,
      id: 3,
      name: 'รอรูปผู้รับ'
    }, {
      filter_count: 2,
      id: 4,
      name: 'ส่งรูปผู้รับแล้ว'
    }],
    location_filters: [{
      filter_count: 0,
      id: 1,
      name: 'โรงพยาบาล'
    }, {
      filter_count: 0,
      id: 2,
      name: 'ทาวน์โฮม'
    }],
    store_filters: [{
      filter_count: 0,
      id: 1,
      name: 'หรีด ณ วัด'
    }]
  },
  store_types: [{
    id: 1,
    name: 'พวงหรีด'
  }, {
    id: 2,
    name: 'ดอกไม้'
  }],
  types: [{
    id: 1,
    name: 'ค่าใช้จ่าย'
  }, {
    id: 2,
    name: 'มูลค่าบวกเพิ่ม'
  }],
  issue_types: [{
    id: 1,
    name: 'ปัญหาภายนอก',
    remark: ''
  }, {
    id: 2,
    name: 'ปัญหาภายใน',
    remark: ''
  }, {
    id: 3,
    name: 'ปัญหาจากลูกค้า',
    remark: ''
  }, {
    id: 4,
    name: 'ปัญหาจากซัพพลายเออร์',
    remark: ''
  }],
  product_types: [{
    id: 1,
    name: 'สินค้า'
  }, {
    id: 2,
    name: 'ค่าบริการ'
  }, {
    id: 3,
    name: 'อื่นๆ'
  }, {
    id: 4,
    name: 'ส่วนลด/ส่วนเพิ่ม'
  }],
  suppliers: [{
    id: 1,
    supplier_id: 2,
    supplier_name: 'ประดับไม้',
    order_amount_per_day: '1/20',
    order_amount_per_month: '30/200',
    price: 0,
    delivery_price: 20,
    total: 20
  }, {
    id: 2,
    supplier_id: 1,
    supplier_name: 'ชื่นบาน',
    order_amount_per_day: '1/20',
    order_amount_per_month: '30/200',
    price: 190,
    delivery_price: 50,
    total: 240
  }],
  colors: [{
    id: 'มาตรฐาน',
    name: 'มาตรฐาน'
  }, {
    id: 'สีดำ',
    name: 'สีดำ'
  }, {
    id: 'สีขาว',
    name: 'สีขาว'
  }, {
    id: 'อื่นๆ',
    name: 'อื่นๆ'
  }],
  status_inspections: [{
    id: 1,
    name: 'รอการตรวจสอบ', // 'ตรวจสอบออเดอร์แล้ว'
  }, {
    id: 2,
    name: 'ตรวจสอบแล้ว', //'ยังไม่ตรวจสอบออเดอร์แล้ว'
  }],
  // , {
  //   id: 3,
  //   name: 'ส่งออเดอร์แล้ว'
  // }
  bill: {
    id: 1,
    order_id: 1,
    order_code: 'O-0000555',
    sale_id: 1,
    sale_name: 'Test',
    date_order: '21/05/2020',
    customer_id: 1,
    customer_name: 'นางระเบียบ',
    customer_address: '444/444 หนองเขม กทม.',
    customer_tax: '98989898',
    cutomer_phone: '0989898989',
    customer_email: 'cutomer@mail.com',
    supplier_id: 1,
    supplier_name: 'ซัพพลายเออร์ 1',
    supplier_address: '9901/1 ศรีราชา',
    supplier_tax: '112121212',
    supplier_phone: '0323232323',
    orders: [{
      id: 1,
      parent: 1,
      child: '',
      product_id: 1,
      product_code: 'B124',
      product_name: 'บุษปวัน',
      price: '300',
      // parent: [{
      //   product_id: 2,
      //   product_code: 'A215',
      //   product_name: 'กุหลาบ',
      //   upsale: true,
      //   cost: '100'
      // }],
      upsale: true,
      total: '400',
      seq: '1',
      rowspan: 2
    }, {
      id: 2,
      parent: 1,
      child: 1,
      product_id: 2,
      product_code: 'A215',
      product_name: 'กุหลาบ',
      price: '100',
      upsale: true,
      total: '',
      seq: '1.1',
      rowspan: ''
    }, {
      id:3,
      parent: 2,
      child: '',
      product_id: 3,
      product_code: 'A0XX',
      product_name: 'พวงหรีดดอกไม้สด',
      price: '300',
      // parent: [],
      upsale: false,
      total: '300',
      seq: '2',
      rowspan: ''
    }],
    service: 100,
    amount: 700,
    total: 800
  },
  issue_type: [{
    id: 1,
    name: 'ปัญหาภายนอก',
    remark: ''
  }, {
    id: 2,
    name: 'ปัญหาภายใน',
    remark: ''
  }, {
    id: 3,
    name: 'ปัญหาจากลูกค้า',
    remark: ''
  }, {
    id: 4,
    name: 'ปัญหาจากซัพพลายเออร์',
    remark: ''
  }]
}
